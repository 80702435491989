<template>
  <v-dialog v-model="show" persistent max-width="750px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="2">
                <v-text-field v-model="codigo" label="Código" outlined dense />
              </v-col>
              <v-col cols="8">
                <v-text-field v-model="nombre" label="Nombre*" :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="2">
                <v-checkbox v-model="borrado" label="Activo" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="categoriaSelected"
                  label="Categoria*"
                  :items="categorias"
                  item-text="NOMBRE"
                  return-object
                  :rules="[globalRules.required]"
                  outlined
                  dense
                >
                  <!-- Slot append para mostrar tanto la 'X' como la flecha -->
                  <template #append>
                    <v-icon @click="resetCategoria" v-if="categoriaSelected">mdi-close</v-icon>
                    <v-icon v-if="!categoriaSelected">mdi-menu-down</v-icon> <!-- Flecha de despliegue -->
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="tipoSelected"
                  label="Tipo*"
                  :items="tiposFiltered"
                  item-text="NOMBRE"
                  return-object
                  :rules="[globalRules.required]"
                  outlined
                  dense
                >
                  <!-- Slot append para mostrar tanto la 'X' como la flecha -->
                  <template #append>
                    <v-icon @click="resetTipo" v-if="tipoSelected">mdi-close</v-icon>
                    <v-icon v-if="!tipoSelected">mdi-menu-down</v-icon> <!-- Flecha de despliegue -->
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-autocomplete v-model="organismosSelected" label="Organismo*" :items="organismos" item-text="ORGANISMO" return-object outlined dense :rules="[globalRules.required]" />
              </v-col>
              <v-col cols="4">
                <v-autocomplete v-model="municipioSelected" label="Municipio*" :items="municipiosFiltered" item-text="NOMBRE" return-object :rules="[globalRules.required]" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="direccion" label="Dirección" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="mup" label="MUP" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="zepa" label="ZEPA" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="lic" label="LIC" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="enp" label="ENP" outlined dense />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="wifi" label="WIFI" outlined dense />
              </v-col>
              <v-col cols="4">
                <VueCtkDateTimePicker
                  v-model="fechaAltaSelected" no-value-to-custom-elem only-date
                  color="#00759e" button-now-translation="Ahora"
                  format="DD/MM/YYYY" :right="true"
                >
                  <v-text-field v-model="fechaAltaSelected" label="Fecha alta" readonly outlined dense prepend-inner-icon="mdi-calendar" />
                </VueCtkDateTimePicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="X" label="X*" type="number" outlined dense :rules="[globalRules.required, (value) => validarCoordenada(value, 'X')]" />
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="Y" label="Y*" type="number" outlined dense :rules="[globalRules.required, (value) => validarCoordenada(value, 'Y')]" />
              </v-col>
              <v-col cols="4">
                <VueCtkDateTimePicker
                  v-model="fechaBajaSelected" no-value-to-custom-elem only-date
                  color="#00759e" button-now-translation="Ahora"
                  format="DD/MM/YYYY" :right="true"
                >
                  <v-text-field v-model="fechaBajaSelected" label="Fecha baja" readonly outlined dense prepend-inner-icon="mdi-calendar" />
                </VueCtkDateTimePicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea rows="2" v-model="descripcion" label="Descripción" outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from '@/helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedInfraestructura: Object
  },
  data () {
    return {
      nombre: null,
      codigo: null,
      tipoSelected: null,
      categoriaSelected: null,
      provinciaSelected: null,
      organismosSelected: null,
      municipioSelected: null,
      direccion: null,
      fechaAltaSelected: null,
      fechaBajaSelected: null,
      X: null,
      Y: null,
      descripcion: null,
      borrado: null,
      mup: null,
      zepa: null,
      lic: null,
      enp: null,
      wifi: null,
      isValid: false,
      municipiosFiltered: [],
      provinciasFiltered: [],
      tiposFiltered: [],
      categoriasFiltered: [],
      sortedOrganismos: []
    }
  },
  computed: {
    ...mapGetters('infraestructura', [
      'infraestructuras', 'tipos', 'categorias', 'municipios', 'provincias'
    ]),
    ...mapGetters('organismo', [
      'organismos'
    ]),
    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },
    infraestructuras () {
      let infraestructuras = JSON.parse(JSON.stringify(this.$store.getters['infraestructura/infraestructuras']))
      return infraestructuras.sort((a, b) => constants.collator.compare(a.INFRAESTRUCTURA, b.INFRAESTRUCTURA))
    },
    tipos () {
      let tipos = JSON.parse(JSON.stringify(this.$store.getters['infraestructura/tipos']))
      return tipos.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
    },
    categorias () {
      let categorias = JSON.parse(JSON.stringify(this.$store.getters['infraestructura/categorias']))
      return categorias.sort((a, b) => constants.collator.compare(a.CATEGORIA, b.CATEGORIA))
    },
    municipios () {
      let municipios = JSON.parse(JSON.stringify(this.$store.getters['infraestructura/municipios']))
      return municipios.sort((a, b) => constants.collator.compare(a.MUNICIPIO, b.MUNICIPIO))
    },
    provincias () {
      let provincias = JSON.parse(JSON.stringify(this.$store.getters['infraestructura/provincias']))
      return provincias.sort((a, b) => constants.collator.compare(a.PROVINCIA, b.PROVINCIA))
    },
    organismos () {
      let organismos = JSON.parse(JSON.stringify(this.$store.getters['organismo/organismos']))
      return organismos.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
    }
  },
  watch: {
    show () {
      this.tiposFiltered = this.tipos
      if (this.show) {
        if (this.isEdit) {
          this.tipoSelected = this.tipos.find(x => x.ID_INFRAESTRUCTURA_TIPO === this.editedInfraestructura.ID_INFRAESTRUCTURA_TIPO)
          this.municipioSelected = this.municipios.find(x => x.ID_MUNICIPIO === this.editedInfraestructura.ID_MUNICIPIO)
          this.provinciaSelected = this.municipioSelected
            ? this.municipioSelected.PROVINCIA
            : null
          this.organismosSelected = this.editedInfraestructura.ID_ORGANISMO
            ? this.organismos.find(x => x.ID_ORGANISMO === this.editedInfraestructura.ID_ORGANISMO)
            : this.organismos.find(x => x.ORGANISMO === 'OTROS')

          this.nombre = this.editedInfraestructura.NOMBRE
          this.codigo = this.editedInfraestructura.CODIGO
          this.direccion = this.editedInfraestructura.DIRECCION
          this.fechaAltaSelected = this.editedInfraestructura.FECHA_ALTA ? this.$date.formatDate(this.editedInfraestructura.FECHA_ALTA, 'DD/MM/YYYY') : null
          this.fechaBajaSelected = this.editedInfraestructura.FECHA_BAJA ? this.$date.formatDate(this.editedInfraestructura.FECHA_BAJA, 'DD/MM/YYYY') : null
          this.X = this.editedInfraestructura.X
          this.Y = this.editedInfraestructura.Y
          this.descripcion = this.editedInfraestructura.DESCRIPCION
          this.borrado = !this.editedInfraestructura.BORRADO
          this.mup = this.editedInfraestructura.MUP
          this.zepa = this.editedInfraestructura.ZEPA
          this.lic = this.editedInfraestructura.LIC
          this.enp = this.editedInfraestructura.ENP
          this.wifi = this.editedInfraestructura.WIFI
        } else {
          this.fechaAltaSelected = this.$date.now().format('DD/MM/YYYY')
          this.borrado = true
        }

        this.provinciasFiltered = this.provincias
        this.municipiosFiltered = this.municipios
      }
    },
    municipioSelected () {
      if (this.municipioSelected) {
        this.provinciaSelected = this.municipioSelected.PROVINCIA
      } else {
        this.provinciaSelected = null
      }
    },
    provinciaSelected () {
      // Si provincia seleccionada existe entonces filtra los municipios asociados a la provincia
      if (this.provinciaSelected) {
        this.municipiosFiltered = this.municipios.filter(municipio => municipio.PROVINCIA.ID_INE_PROVINCIA === this.provinciaSelected.ID_INE_PROVINCIA)
        // si no hay municipios asociados a la provincia elimina el municipio seleccionado
        if (this.municipiosFiltered.length === 0) {
          this.municipioSelected = null
        }
      } else {
        this.municipiosFiltered = this.municipios
      }
      let collator = new Intl.Collator('es', { sensitivity: 'base' })
      this.municipiosFiltered.sort((a, b) => collator.compare(a.NOMBRE, b.NOMBRE))
    },
    tipoSelected () {
      if (this.tipoSelected !== null) {
        this.categoriaSelected = this.categorias.find(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.tipoSelected.ID_INFRAESTRUCTURA_CATEGORIA)
      }
    },
    categoriaSelected () {
      if (this.categoriaSelected) {
        // Filtrar tipos por la categoría seleccionada
        this.tiposFiltered = this.tipos.filter(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA)

        // Si el tipo seleccionado no está en los tipos filtrados, lo reseteamos
        if (!this.tiposFiltered.find(x => x.ID_INFRAESTRUCTURA_TIPO === this.tipoSelected?.ID_INFRAESTRUCTURA_TIPO)) {
          this.tipoSelected = null
        }
      }
    }
  },
  methods: {
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },
    aceptar () {
      let infraestructura = this.isEdit ? JSON.parse(JSON.stringify(this.editedInfraestructura)) : {}
      infraestructura.NOMBRE = this.nombre
      infraestructura.CODIGO = this.codigo
      infraestructura.ID_INFRAESTRUCTURA_TIPO = this.tipoSelected.ID_INFRAESTRUCTURA_TIPO
      infraestructura.TIPO = this.tipoSelected.NOMBRE
      infraestructura.CATEGORIA = this.tipoSelected.CATEGORIA.NOMBRE
      infraestructura.ID_ORGANISMO = this.organismosSelected.ID_ORGANISMO
      infraestructura.ORGANISMO = this.organismosSelected.ORGANISMO
      infraestructura.ID_MUNICIPIO = this.municipioSelected.ID_MUNICIPIO
      infraestructura.MUNICIPIO = this.municipioSelected.NOMBRE
      infraestructura.DEMARCACION = this.municipioSelected.DEMARCACION.DEMARCACION
      infraestructura.ID_INE_PROVINCIA = this.provinciaSelected.ID_INE_PROVINCIA
      infraestructura.PROVINCIA = this.provinciaSelected.LITERAL
      infraestructura.DIRECCION = this.direccion
      infraestructura.FECHA_ALTA = this.fechaAltaSelected !== '' && this.fechaAltaSelected != null ? this.$date.parseDate(this.fechaAltaSelected, 'DD/MM/YYYY').format('YYYY/MM/DD') : null
      infraestructura.FECHA_BAJA = this.fechaBajaSelected !== '' && this.fechaBajaSelected != null ? this.$date.parseDate(this.fechaBajaSelected, 'DD/MM/YYYY').format('YYYY/MM/DD') : null
      infraestructura.X = Number(this.X)
      infraestructura.Y = Number(this.Y)
      infraestructura.DESCRIPCION = this.descripcion
      infraestructura.BORRADO = !this.borrado
      infraestructura.MUP = this.mup
      infraestructura.ZEPA = this.zepa
      infraestructura.LIC = this.lic
      infraestructura.ENP = this.enp
      infraestructura.WIFI = this.wifi
      if (this.isEdit) {
        this.$emit('editInfraestructura', infraestructura)
      } else {
        infraestructura.ID_INFRAESTRUCTURA = this.$uuid.createUUID()
        this.$emit('addInfraestructura', infraestructura)
      }
      this.$refs.form.reset()
    },
    validarCoordenada (value, tipo) {
      if (!value) return `La coordenada ${tipo} es obligatoria.`
      if (tipo === 'X' && (value < 500000 || value > 700000)) return 'X debe estar entre 550000 y 700000.'
      if (tipo === 'Y' && (value < 4600000 || value > 4900000)) return 'Y debe estar entre 4600000 y 4900000.'
      return true
    },
    resetCategoria () {
      this.categoriaSelected = null
      this.tipoSelected = null
      this.tiposFiltered = this.tipos
    },
    resetTipo () {
      this.tipoSelected = null
      this.tiposFiltered = this.tipos.filter(x => x.ID_INFRAESTRUCTURA_CATEGORIA === this.categoriaSelected.ID_INFRAESTRUCTURA_CATEGORIA)
    }
  }
}
</script>

<style>
</style>
