import Vue from 'vue'
import Vuex from 'vuex'
// import { createLogger } from 'vuex/dist/logger'

import notifications from './plugins/notifications'

import app from './modules/app'
import auth from './modules/auth'
import map from './modules/map'
import shareMap from './modules/shareMap'

import incendio from './modules/incendio'
import medio from './modules/medio'
import parametro from './modules/parametro'
import usuario from './modules/usuario'
import simulacion from './modules/simulacion'

import infraestructura from './modules/infraestructura'
import organismo from './modules/organismo'
import demarcacion from './modules/demarcacion'
import base from './modules/base'

import vuexPersistedstate from 'vuex-persistedstate'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

const persistedState = vuexPersistedstate({
  key: 'rioja_app_state',
  reducer: state => ({
    auth: state.auth,
    usuario: state.usuario
  })
})

export default new Vuex.Store({
  modules: {
    app,
    auth,
    map,
    shareMap,
    incendio,
    medio,
    parametro,
    usuario,
    simulacion,
    organismo,
    infraestructura,
    demarcacion,
    base

  },
  plugins: [persistedState, notifications],
  strict: debug
})
