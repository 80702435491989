<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="dataForm.medio" label="Medio*" :rules="[globalRules.required]" outlined dense class="compact" />
              </v-col>

              <v-col>
                <v-select v-model="dataForm.tipoSelected" label="Tipo*" :items="tiposMedio" item-text="TIPO" item-value="TIPO" return-object :rules="[globalRules.required]" outlined dense class="compact" />
              </v-col>
            </v-row>

            <v-row v-show="dataForm.tipoSelected && dataForm.tipoSelected.CATEGORIA === 'Aéreo'">
              <v-col>
                <v-text-field label="Velocidad máxima (km/h)*" v-model="dataForm.velocidadMaxima" maxlength="9" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>
              <v-col>
                <v-text-field label="Modelo*" v-model="dataForm.modelo" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Nombre*" v-model="dataForm.nombre" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Teléfono interno*" maxlength="9" v-model="dataForm.tlfInterno" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>

              <v-col>
                <v-text-field label="Teléfono externo*" maxlength="9" v-model="dataForm.tlfExterno" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select label="Zona*" :items="zonasMedio" item-text="ZONA" item-value="ZONA" v-model="dataForm.zonaSelected" return-object outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>

              <!--<v-col>
                <v-text-field label="Guardias*" v-model="dataForm.guardias" outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col> -->
            </v-row>

            <v-row>
              <v-col>
                <v-select label="Base*" :items="bases" item-text="NOMBRE" item-value="NOMBRE" v-model="dataForm.baseSelected" return-object outlined dense :rules="[globalRules.required]" class="compact" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu ref="menuHoraEntrada" v-model="showHoraEntrada" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="dataForm.horaEntrada" label="Hora entrada*" class="compact" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense :rules="[globalRules.required]" />
                  </template>
                  <v-time-picker v-model="dataForm.horaEntrada" v-show="showHoraEntrada" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraEntrada = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraEntrada.save(dataForm.horaEntrada)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col>
                <v-menu ref="menuHoraSalida" v-model="showHoraSalida" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="dataForm.horaSalida" label="Hora salida*" class="compact" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" outlined dense :rules="[globalRules.required]" />
                  </template>
                  <v-time-picker v-model="dataForm.horaSalida" v-show="showHoraSalida" format="24hr">
                    <v-spacer />
                    <v-btn text color="primary" @click="showHoraSalida = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.menuHoraSalida.save(dataForm.horaSalida)">OK</v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedMedio: Object
  },

  data: () => ({
    isValid: false,
    showHoraEntrada: false,
    showHoraSalida: false,

    dataForm: {
      medio: null,
      velocidadMaxima: null,
      modelo: null,
      nombre: null,
      tlfInterno: null,
      tlfExterno: null,
      // guardias: null,
      horaEntrada: null,
      horaSalida: null,

      tipoSelected: null,
      zonaSelected: null,
      baseSelected: null
    },

    globalRules: {
      required: value => !!value || 'Este campo es obligatorio'
    }
  }),

  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),

    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },

    bases () {
      let bases = JSON.parse(JSON.stringify(this.$store.getters['medio/bases']))
      bases.unshift({ ID_BASE: null, NOMBRE: 'Ninguna' }) // TODO: añadir en base de datos 'Ninguna'
      return bases
    },

    zonasMedio () {
      let zonas = JSON.parse(JSON.stringify(this.$store.getters['medio/zonas']))
      zonas.unshift({ ID_MEDIO_ZONA: null, ZONA: 'Ninguna' }) // TODO: añadir en base de datos 'Ninguna'
      return zonas
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          this.dataForm.medio = this.editedMedio.MEDIO
          this.dataForm.tipoSelected = this.tiposMedio.find(x => x.TIPO === this.editedMedio.TIPO)
          this.dataForm.velocidadMaxima = this.editedMedio.VELOCIDAD_MAX
          this.dataForm.modelo = this.editedMedio.MODELO
          this.dataForm.nombre = this.editedMedio.NOMBRE
          this.dataForm.tlfInterno = this.editedMedio.TELEFONO_INTERNO
          this.dataForm.tlfExterno = this.editedMedio.TELEFONO_EXTERNO
          this.dataForm.zonaSelected = this.zonasMedio.find(x => x.ZONA === this.editedMedio.ZONA)
          if (!this.dataForm.zonaSelected) {
            this.dataForm.zonaSelected = this.zonasMedio[0]
          }
          // this.dataForm.guardias = this.editedMedio.GUARDIAS
          this.dataForm.baseSelected = this.bases.find(x => x.NOMBRE === this.editedMedio.BASE)
          if (!this.dataForm.baseSelected) {
            this.dataForm.baseSelected = this.bases[0]
          }
          this.dataForm.horaEntrada = this.editedMedio.HORA_ENTRADA
          this.dataForm.horaSalida = this.editedMedio.HORA_SALIDA
        }
      }
    },
    dataForm: {
      handler () {
        this.checkFormValidity()
      },
      deep: true
    }
  },

  methods: {
    checkFormValidity () {
      this.isValid =
        !!this.dataForm.medio &&
        !!this.dataForm.tipoSelected &&
        (this.aereoSelected ? !!this.dataForm.velocidadMaxima : true) &&
        (this.aereoSelected ? !!this.dataForm.modelo : true) &&
        !!this.dataForm.nombre &&
        !!this.dataForm.tlfInterno &&
        !!this.dataForm.tlfExterno &&
        !!this.dataForm.zonaSelected &&
        // !!this.dataForm.guardias &&
        !!this.dataForm.baseSelected &&
        !!this.dataForm.horaEntrada &&
        !!this.dataForm.horaSalida
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {
      let medio = this.isEdit ? JSON.parse(JSON.stringify(this.editedMedio)) : {}
      if (this.dataForm.tipoSelected.CATEGORIA === 'Aéreo') {
        medio.VELOCIDAD_MAX = this.dataForm.velocidadMaxima
        medio.MODELO = this.dataForm.modelo
      } else {
        medio.VELOCIDAD_MAX = null
        medio.MODELO = null
      }

      medio.MEDIO = this.dataForm.medio

      // Tipo / Categoria
      medio.TIPO = this.dataForm.tipoSelected.TIPO
      medio.CATEGORIA = this.dataForm.tipoSelected.CATEGORIA
      medio.ID_MEDIO_TIPO = this.dataForm.tipoSelected.ID_MEDIO_TIPO

      medio.NOMBRE = this.dataForm.nombre

      medio.TELEFONO_INTERNO = this.dataForm.tlfInterno
      medio.TELEFONO_EXTERNO = this.dataForm.tlfExterno

      // Zona
      medio.ZONA = this.dataForm.zonaSelected.ZONA === 'Ninguna' ? '' : this.dataForm.zonaSelected.ZONA
      medio.ID_MEDIO_ZONA = this.dataForm.zonaSelected.ID_MEDIO_ZONA === '' ? 'NULL' : this.dataForm.zonaSelected.ID_MEDIO_ZONA

      // medio.GUARDIAS = this.dataForm.guardias

      // Base
      medio.BASE = this.dataForm.baseSelected.NOMBRE === 'Ninguna' ? '' : this.dataForm.baseSelected.NOMBRE
      medio.ID_BASE = this.dataForm.baseSelected.ID_BASE === '' ? 'NULL' : this.dataForm.baseSelected.ID_BASE

      medio.HORA_ENTRADA = this.dataForm.horaEntrada
      medio.HORA_SALIDA = this.dataForm.horaSalida

      if (this.isEdit) {
        this.$emit('editMedio', medio)
      } else {
        medio.ID_MEDIO = this.$uuid.createUUID()
        medio.ESTADO = 24
        this.$emit('addMedio', medio)
      }

      this.$refs.form.reset()
    }
  }
}
</script>

<style scoped>
.compact ::v-deep(.v-text-field__details){
  height: fit-content;
  margin: 0;
}
</style>
