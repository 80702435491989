<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Imágenes Satélite</v-toolbar-title>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="cleanSateliteImage">
              <v-icon color="primary">mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Limpiar imagen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-show="showDetailsNBRNDVI || showDetailsRGBComp" v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="downloadSateliteImagen(URL_WMS_PNG, itemLabel, 'png')" :style="{marginRight: '3.2rem'}">
              <v-icon v-if="!isDownloadingPNG" color="primary">mdi-file-image-outline</v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="20" width="2" />
            </v-btn>
          </template>
          <span>Descargar PNG</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-show="showDetailsNBRNDVI || showDetailsRGBComp" v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="downloadSateliteImagen(URL_WMS_TIFF, itemLabel, 'tiff')" :style="{marginRight: '6.4rem'}">
              <v-icon v-if="!isDownloadingTIFF" color="primary">mdi-file-image-marker-outline</v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="20" width="2" />
            </v-btn>
          </template>
          <span>Descargar TIFF</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-show="showDetailsNBRNDVI" v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="showLegend = !showLegend" :style="{marginRight: '9.6rem'}">
              <v-icon color="primary">mdi-map-legend</v-icon>
            </v-btn>
          </template>
          <span>Mostrar leyenda</span>
        </v-tooltip>
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="8">
            <v-tabs v-model="tabSateliteSelected" slider-color="secondary">
              <v-tab>Iniciales</v-tab>
              <v-tab>Finales</v-tab>
              <v-tab v-if="incendio && buscarFechaPorEstado(incendio.ESTADOS, 'Finalizado') !== null">Comparaciones</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="2">
            <!-- Segunda columna vacía -->
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-slider
          v-model="maxCloudCoverage"
          label="Máxima cobertura de nubes"
          :min="0"
          :max="100"
          color="primary"
          hide-details
        >
          <template #append>
            <span>{{ maxCloudCoverage }}%</span>
          </template>
        </v-slider>
      </v-container>
      <v-container v-if="tabSateliteSelected == 0">
        <v-row>
          <v-col v-for="(item, index) in buttonsIniciales" :key="index">
            <v-card
              v-model.lazy="item.visible"
              class="pa-2"
              @click="getSateliteImageBbox(item.label)"
              v-if="item.visible"
            >
              <v-row>
                <v-col>
                  {{ item.label }}
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-progress-circular v-show="showProgress && item.label == itemLabel" color="primary" indeterminate :size="20" :width="2" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="tabSateliteSelected == 1">
        <v-row>
          <v-col v-for="(item, index) in buttonsFinales" :key="index">
            <v-card
              class="pa-2"
              @click="getSateliteImageBbox(item.label)"
              v-if="item.visible"
            >
              <v-row>
                <v-col>
                  {{ item.label }}
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-progress-circular v-show="showProgress && item.label == itemLabel" color="primary" indeterminate :size="20" :width="2" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="tabSateliteSelected == 2">
        <v-row>
          <v-col v-for="(item, index) in buttonsComparacion" :key="index">
            <v-card
              class="pa-2"
              @click="getSateliteImageBbox(item.label)"
              v-if="item.visible"
            >
              <v-row>
                <v-col>
                  {{ item.label }}
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-progress-circular v-show="showProgress && item.label == itemLabel" color="primary" indeterminate :size="20" :width="2" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-show="showDetailsNBRNDVI || showDetailsRGBComp" style="alert">
        <v-alert dense color="#a5cadb">
          Fecha de adquisición: {{ WFSAcquisitionDate }}
        </v-alert>
        <v-alert dense color="#a5cadb">
          Cobertura de nubes: {{ WFSCloudCoverage }}%
        </v-alert>
      </v-container>
      <div class="legend-container">
        <v-card
          v-if="showLegend"
          class="legend-panel"
          outlined
          elevation="2"
        >
          <v-list dense>
            <v-list-item v-for="(item, index) in (itemLabel === 'NBR' ? legendNBRItems : legendNDVIItems)" :key="index">
              <v-list-item-avatar :style="{ backgroundColor: item.color}" class="square-avatar" />
              <v-list-item-title class="ml-3 title-large">{{ item.description }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'
import * as ArcGIS from '@/helpers/ArcGIS'
import axios from 'axios'

export default {
  components: {},

  props: {
    idIncendio: String
  },

  data: () => ({
    buttonsIniciales: [
      { label: 'NDVI', visible: true, nombre: null, activate: true },
      { label: 'RGB', visible: true, nombre: null, activate: true }
    ],
    buttonsFinales: [
      { label: 'NBR', visible: true, nombre: null, activate: true },
      { label: 'NDVI', visible: true, nombre: null, activate: true },
      { label: 'RGB', visible: true, nombre: null, activate: true }
    ],
    buttonsComparacion: [
      { label: 'Raster NBR', visible: true, nombre: null, activate: true },
      { label: 'Raster NDVI', visible: true, nombre: null, activate: true }
    ],
    tabSateliteSelected: 0, // 0: Inicial, 1: Final, 2: Comparacion
    apiSelected: 'COPERNICUS',
    maxCloudCoverage: 25,
    showDetailsNBRNDVI: false,
    showDetailsRGBComp: false,
    showProgress: false,
    itemLabel: '',
    WFSAcquisitionDate: '',
    WFSCloudCoverage: '',
    showLegend: false,
    URL_WMS_PNG: '',
    URL_WMS_TIFF: '',
    isDownloadingPNG: false,
    isDownloadingTIFF: false,
    legendNBRItems: [
      { color: '#000000', description: 'Áreas sin vegetación o terrenos completamente quemados.' },
      { color: '#1a1a1a', description: 'Vegetación muy dañada o quemada, áreas de fuego reciente' },
      { color: '#333333', description: 'Vegetación parcialmente dañada o terreno con vegetación escasa.' },
      { color: '#4d4d4d', description: 'Áreas con vegetación moderadamente dañada o en recuperación.' },
      { color: '#808080', description: 'Vegetación parcialmente recuperada o con daños leves por fuego.' },
      { color: '#b3b3b3', description: 'Vegetación saludable, sin daños significativos por fuego.' },
      { color: '#f2f2f2', description: 'Vegetación completamente recuperada o sin daños por el fuego.' }
    ],
    legendNDVIItems: [
      { color: '#330000', description: 'Vegetación inexistente como terreno desnudo o agua.' },
      { color: '#c7140e', description: 'Vegetación muy escasa o terreno muy seco.' },
      { color: '#ed6e08', description: 'Vegetación escasa o débil.' },
      { color: '#ffffbf', description: 'Vegetación moderada o en regeneración.' },
      { color: '#d9ef8b', description: 'Vegetación saludable con baja densidad.' },
      { color: '#66bd63', description: 'Vegetación densa y saludable.' },
      { color: '#006837', description: 'Vegetación muy densa y saludable.' }
    ]
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },
    allImagenSatelite () {
      return this.$store.getters['incendio/imagenesSatelite']
    }
  },

  watch: {
    async idIncendio () {
      if (this.idIncendio != null) {
        await this.reset()
        await this.fetchImagenesSatelite({ ID_INCENDIO: this.idIncendio })

        this.tabSateliteSelected = 0

        this.imagenesIniciales(this.apiSelected) // "COPERNICUS"
      }
    },
    async tabSateliteSelected () {
      await this.reset()

      this.hideImageRaster()

      switch (this.tabSateliteSelected) {
        case 0:
          this.imagenesIniciales(this.apiSelected)
          break
        case 1:
          this.imagenesFinales(this.apiSelected)
          break
        case 2:
          this.imagenesComparacion(this.apiSelected)
          break
        default:
          break
      }
    }
  },

  methods: {
    ...mapActions('incendio', ['fetchImagenesSatelite']),

    imagenesIniciales (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_INICIAL_' + api
        ) {
          this.buttonsIniciales[0].visible = true
          this.buttonsIniciales[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_INICIAL_' + api
        ) {
          this.buttonsIniciales[1].visible = true
          this.buttonsIniciales[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'RGB_INICIAL_' + api
        ) {
          this.buttonsIniciales[2].visible = true
          this.buttonsIniciales[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },
    imagenesFinales (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_FINAL_' + api
        ) {
          this.buttonsFinales[0].visible = true
          this.buttonsFinales[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_FINAL_' + api
        ) {
          this.buttonsFinales[1].visible = true
          this.buttonsFinales[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'RGB_FINAL_' + api
        ) {
          this.buttonsFinales[2].visible = true
          this.buttonsFinales[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },
    imagenesComparacion (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_COMPARACION_' + api
        ) {
          this.buttonsComparacion[0].visible = true
          this.buttonsComparacion[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_COMPARACION_' + api
        ) {
          this.buttonsComparacion[1].visible = true
          this.buttonsComparacion[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }

        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_COMP_' + api + '_SHP'
        ) {
          this.buttonsComparacion[2].visible = true
          this.buttonsComparacion[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_COMP_' + api + '_SHP'
        ) {
          this.buttonsComparacion[3].visible = true
          this.buttonsComparacion[3].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },

    async showImage (idImagen, estado, i) {
      if (estado === 'inicial') {
        if (this.buttonsIniciales[i].activate === true) {
          this.hideImageRaster()
          this.buttonsIniciales[i].activate = false
          return
        } else {
          this.buttonsIniciales[i].activate = true

          for (let j = 0; j < this.buttonsIniciales.length; j++) {
            if (j !== i) {
              this.buttonsIniciales[j].activate = false
            }
          }
        }
      } else if (estado === 'final') {
        if (this.buttonsFinales[i].activate === true) {
          this.hideImageRaster()
          this.buttonsFinales[i].activate = false
          return
        } else {
          this.buttonsFinales[i].activate = true

          for (let j = 0; j < this.buttonsFinales.length; j++) {
            if (j !== i) {
              this.buttonsFinales[j].activate = false
            }
          }
        }
      } else {
        if (this.buttonsComparacion[i].activate === true) {
          if (i > 1) {
            this.hideImageVector()
          } else {
            this.hideImageRaster()
          }
          this.buttonsComparacion[i].activate = false
          return
        } else {
          this.buttonsComparacion[i].activate = true

          if (i < 2) {
            this.buttonsComparacion[i === 1 ? 0 : 1].activate = false
          } else {
            this.buttonsComparacion[i === 2 ? 3 : 2].activate = false
          }
        }
      }

      // Elimina la extension del nombre de la imagen
      if (idImagen.includes('.tiff')) {
        idImagen = idImagen.substring(0, idImagen.length - 5)
      } else {
        // Caso de ".geojson"
        idImagen = idImagen.substring(0, idImagen.length - 8)
      }

      let data = {
        ID_INCENDIO: this.idIncendio,
        NOMBRE_FICHERO: idImagen,
        ESTADO: estado,
        API: this.apiSelected.toLowerCase()
      }

      if ((estado === 'comparacion') & (i > 1)) {
        let response = await api.imagenSatelite.getImagenSatelite(data)
        // ArcGIS.drawGeoJsonImagenSatelite(response.data)
      } else {
        let url = api.imagenSatelite.getImagenSateliteURL(data)
        ArcGIS.drawImagenSatelite(
          {
            ID_INCENDIO: this.idIncendio,
            ID_IMAGEN: idImagen,
            LATITUD: this.incendio.LATITUD,
            LONGITUD: this.incendio.LONGITUD
          },
          url
        )
      }
    },

    // Reset de los botones
    reset () {
      this.buttonsIniciales.forEach((elem) => {
        elem.activate = false
        elem.visible = true
        elem.nombre = null
      })

      this.buttonsFinales.forEach((elem) => {
        elem.activate = false
        elem.visible = true
        elem.nombre = null
      })

      this.buttonsComparacion.forEach((elem) => {
        elem.activate = false
        elem.visible = true
        elem.nombre = null
      })
      this.showDetailsNBRNDVI = false
      this.showDetailsRGBComp = false
      this.showLegend = false
      this.showError = false
      this.itemLabel = ''
      this.URL_WMS_PNG = ''
      this.URL_WMS_TIFF = ''
      this.isDownloadingPNG = false
      this.isDownloadingTIFF = false
    },

    hideImageRaster () {
      // Oculta las imagenes de la capa
      ArcGIS.hideImagenSatelite()
    },
    hideImageVector () {
      // Oculta las imagenes de la capa
      ArcGIS.hideImagenSateliteGeoJSON()
    },

    cleanSateliteImage () {
      ArcGIS.hideImagenSatelite()
      this.showDetailsNBRNDVI = false
      this.showDetailsRGBComp = false
      this.showLegend = false
      this.showError = false
    },

    async getSateliteImageBbox (index) {
      this.cleanSateliteImage()

      let fromDate = '2014-04-03T00:00:00.000Z'
      let toDate = new Date().toISOString()
      let priority = 'mostRecent'

      if (this.incendio.ESTADOS.length !== 0) {
        try {
          let bboxInicial = ArcGIS.generarBbox3857(this.incendio.LATITUD, this.incendio.LONGITUD)
          let bbox = JSON.stringify(bboxInicial)
          if (this.tabSateliteSelected === 0) {
            // INICIALES
            toDate = this.buscarFechaPorEstado(this.incendio.ESTADOS, 'Entrada')
            this.fetchAndDisplaySatelliteData(bbox, bboxInicial, index, fromDate, toDate, priority)
          } else if (this.tabSateliteSelected === 1) {
            // FINALES
            fromDate = this.buscarFechaPorEstado(this.incendio.ESTADOS, 'Finalizado')
            // No hay fecha de finalización
            if (fromDate === null) {
              fromDate = '2014-04-03T00:00:00.000Z'
              this.fetchAndDisplaySatelliteData(bbox, bboxInicial, index, fromDate, toDate, priority)
            // Si hay fecha de finalización
            } else {
              priority = 'leastRecent'
              let response = await api.imagenSatelite.getWFSData(bbox, fromDate, toDate, this.maxCloudCoverage, priority)
              // No hay datos para el intervalo
              if (response.data === '') {
                fromDate = '2014-04-03T00:00:00.000Z'
                priority = 'mostRecent'
                this.fetchAndDisplaySatelliteData(bbox, bboxInicial, index, fromDate, toDate, priority)
                this.$notify('No existen imágenes para los parámetros requeridos. Mostrando la imagen más reciente.')
              // Si hay datos para el intervalo
              } else {
                this.fetchAndDisplaySatelliteData(bbox, bboxInicial, index, fromDate, toDate, priority)
              }
            }
          } else {
            // COMPARACIONES
            fromDate = this.buscarFechaPorEstado(this.incendio.ESTADOS, 'Entrada')
            toDate = this.buscarFechaPorEstado(this.incendio.ESTADOS, 'Finalizado')
            if (toDate === null) toDate = new Date().toISOString()

            const yesterday = '2014-04-03T00:00:00.000Z'
            const today = new Date().toISOString()
            const priorityWFSComp = 'leastRecent'

            this.showProgress = true
            this.itemLabel = index

            let response = await api.imagenSatelite.getWFSData(bbox, toDate, today, this.maxCloudCoverage, priorityWFSComp)
            if (response.data === '') {
              this.$notify('No existen imágenes para los parámetros requeridos.')
              this.showProgress = false
              return
            }
            let WFSData = response.data
            let [WFSDateTimeData, WFSCloudCoverage] = WFSData
            let WFSEuropeanDateTime = this.formatWFSDateTimeData(WFSDateTimeData)

            this.WFSAcquisitionDate = WFSEuropeanDateTime
            this.WFSCloudCoverage = WFSCloudCoverage

            const toDateComp = WFSDateTimeData.replace(' ', 'T') + 'Z'

            priority = 'mostRecent'

            const urlWMSPng = this.buildWMSURL(bboxInicial, index, fromDate, toDateComp, this.maxCloudCoverage, priority, 'png')
            const urlWMSTiff = this.buildWMSURL(bboxInicial, index, fromDate, toDateComp, this.maxCloudCoverage, priority, 'tiff')

            const isValidPng = await this.validateWMSURL(urlWMSPng)
            const isValidTiff = await this.validateWMSURL(urlWMSTiff)

            if (!isValidPng || !isValidTiff) {
              this.$notify('WMS no devolvió imágenes válidas para la comparación.')
              this.reset()
              this.showProgress = false
              return
            }

            if (isValidPng) this.URL_WMS_PNG = urlWMSPng
            if (isValidTiff) this.URL_WMS_TIFF = urlWMSTiff

            this.showDetailsRGBComp = true
            await ArcGIS.drawImagenSatelite(urlWMSPng, bbox)
            this.showProgress = false
          }
        } catch (error) {
          console.error('Error al mostrar imágen de satélite: ' + error)
        }
      } else {
        this.showError = true
      }
    },

    async fetchAndDisplaySatelliteData (bbox, bboxInicial, index, fromDate, toDate, priority) {
      this.showProgress = true
      this.itemLabel = index

      let response = await api.imagenSatelite.getWFSData(bbox, fromDate, toDate, this.maxCloudCoverage, priority)
      let WFSData = response.data
      let [WFSDateTimeData, WFSCloudCoverage] = WFSData
      let WFSEuropeanDateTime = this.formatWFSDateTimeData(WFSDateTimeData)

      if (index === 'NBR' || index === 'NDVI') {
        this.showDetailsNBRNDVI = true
      } else {
        this.showDetailsRGBComp = true
      }

      this.WFSAcquisitionDate = WFSEuropeanDateTime
      this.WFSCloudCoverage = WFSCloudCoverage

      const urlWMSPng = this.buildWMSURL(bboxInicial, index, fromDate, toDate, this.maxCloudCoverage, priority, 'png')
      this.URL_WMS_PNG = urlWMSPng
      const urlWMSTiff = this.buildWMSURL(bboxInicial, index, fromDate, toDate, this.maxCloudCoverage, priority, 'tiff')
      this.URL_WMS_TIFF = urlWMSTiff

      await ArcGIS.drawImagenSatelite(urlWMSPng, bbox)
      this.showProgress = false
    },

    buildWMSURL (bbox, tipoImagen, fromDate, toDate, maxCloudCoverage, priority, format) {
      let urlBase = 'https://sh.dataspace.copernicus.eu/ogc/wms/382cb5c9-05a8-4fde-9dbf-a5081db6808b?REQUEST=GetMap'
      const bboxString = `${bbox.xmin},${bbox.ymin},${bbox.xmax},${bbox.ymax}`
      const layerValue = this.getLayersValue(tipoImagen)
      return `${urlBase}&BBOX=${bboxString}&LAYERS=${layerValue}&WIDTH=1000&HEIGHT=1000&FORMAT=image/${format}&TIME=${fromDate}/${toDate}&MAXCC=${maxCloudCoverage}&PRIORITY=${priority}`
    },

    async validateWMSURL (url) {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer', validateStatus: status => true })
        const contentType = response.headers['content-type']
        if (contentType.includes('xml') || contentType.includes('html')) {
          const responseText = new TextDecoder('utf-8').decode(response.data)
          if (responseText.includes('<ServiceExceptionReport')) {
            console.error('Error en la respuesta WMS')
            return false
          }
        }
        return true
      } catch (error) {
        console.error('Error al validar la URL WMS')
        return false
      }
    },

    buscarFechaPorEstado (estados, tipoEstado) {
      if (!estados) return null
      const estado = estados.find(e => e.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO === tipoEstado)
      return estado ? estado.FECHA : null
    },

    getLayersValue (tipoImagen) {
      if (tipoImagen === 'NBR') {
        return 'NORMALIZED-BURN-RATIO-2'
      } else if (tipoImagen === 'NDVI') {
        return 'NORMALIZED-DIFFERENCE-VEGETATION-INDEX-2'
      } else if (tipoImagen === 'RGB') {
        return 'TRUE-COLOR-2'
      } else if (tipoImagen === 'Raster NBR') {
        return 'NBRCOMPARISON'
      } else {
        return 'NDVICOMPARISON'
      }
    },

    formatWFSDateTimeData (WFSDateTimeData) {
      let [date, time] = WFSDateTimeData.split(' ')
      let [year, month, day] = date.split('-')
      return `${day}-${month}-${year} ${time.slice(0, 5)}`
    },

    async downloadSateliteImagen (urlwms, label, fileFormat) {
      this[`isDownloading${fileFormat.toUpperCase()}`] = true
      try {
        const response = await axios.get(urlwms, {
          responseType: 'blob'
        })

        const folder = this.getDestinationFolder()
        const formattedDate = this.getLocalFormattedDate()
        const fileName = `ImagenSatelite_${label}_${folder}_${formattedDate}.${fileFormat}`

        const blob = new Blob([response.data], { type: `image/${fileFormat}` })
        const downloadUrl = URL.createObjectURL(blob)

        if (fileFormat === 'png') {
          const img = new Image()
          img.src = downloadUrl
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = img.width
            canvas.height = img.height

            ctx.drawImage(img, 0, 0)

            const stripeHeight = 40
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
            ctx.fillRect(0, 0, canvas.width, stripeHeight)

            const text = `${this.WFSAcquisitionDate}, Sentinel-2 L2A, ${label}, ${this.WFSCloudCoverage}% CC`
            ctx.font = '14px Arial'
            ctx.fillStyle = 'white'
            const textWidth = ctx.measureText(text).width
            const x = (canvas.width - textWidth) / 2
            const y = (stripeHeight / 2) + 5
            ctx.fillText(text, x, y)

            canvas.toBlob((canvasBlob) => {
              const canvasDownloadUrl = URL.createObjectURL(canvasBlob)

              const downloadLink = document.createElement('a')
              downloadLink.href = canvasDownloadUrl
              downloadLink.download = fileName
              document.body.appendChild(downloadLink)
              this.isDownloadingPNG = false
              downloadLink.click()

              document.body.removeChild(downloadLink)
              URL.revokeObjectURL(canvasDownloadUrl)
            })
          }
        } else {
          const downloadLink = document.createElement('a')
          downloadLink.href = downloadUrl
          downloadLink.setAttribute('download', fileName)
          document.body.appendChild(downloadLink)
          this.isDownloadingTIFF = false
          downloadLink.click()

          document.body.removeChild(downloadLink)
          URL.revokeObjectURL(downloadUrl)
        }
      } catch (error) {
        console.error('Error al descargar la imagen de satélite:', error)
        this.isDownloading = false
      }
    },

    getLocalFormattedDate () {
      const now = new Date()
      const day = String(now.getDate()).padStart(2, '0')
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const year = now.getFullYear()
      const hours = String(now.getHours()).padStart(2, '0')
      const minutes = String(now.getMinutes()).padStart(2, '0')
      const seconds = String(now.getSeconds()).padStart(2, '0')

      return `${day}-${month}-${year}_${hours}-${minutes}-${seconds}`
    },

    getDestinationFolder () {
      if (this.tabSateliteSelected === 0) {
        return 'inicial'
      } else if (this.tabSateliteSelected === 1) {
        return 'final'
      } else {
        return 'comparacion'
      }
    }
  },
  created () {}
}

</script>

<style scoped>
.legend-container {
  padding-bottom: 0.75rem;
  size: 3rem;
}

.legend-panel {
  position: relative;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  padding: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 10;
}

.square-avatar {
  max-width: 1rem;
  max-height: 1rem;
  border-radius: 0;
  margin-right: 0rem;
}

.title-large {
  font-size: 14px !important;
}
</style>
